import { createRouter, createWebHistory } from "vue-router";
import about from "../views/About.vue";
import projects from "../views/Projects.vue";
import contact from "../views/Contact.vue";
import home from "../views/Home.vue";
import OperationalDesign from "../views/services/OperationalDesign.vue";
import MaintenanceConsulting from "../views/services/MaintenanceConsulting.vue";
import OperationalConsulting from "../views/services/OperationalConsulting.vue";
import TransportationConsulting from "../views/services/TransportationConsulting.vue";
import WasteConsulting from "../views/services/WasteConsulting.vue";
import SludgeFund from "../views/services/SludgeFund.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: home,
    meta: { title: "עמוד בית - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/about",
    name: "About",
    component: about,
    meta: { title: "אודות - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/projects",
    name: "Projects",
    component: projects,
    meta: { title: "פרויקטים - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: contact,
    meta: { title: "צור קשר - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/operationalDesign",
    name: "OperationalDesign",
    component: OperationalDesign,
    meta: { title: '"בינוי תומך תפעול | גיבוש פרוגרמה תפעולית ארגונית - RVc' },
  },
  {
    path: "/maintenanceConsulting",
    name: "MaintenanceConsulting",
    component: MaintenanceConsulting,
    meta: { title: "ייעוץ תחזוקה | ייעוץ אחזקה - RVc" },
  },
  {
    path: "/operationalConsulting",
    name: "OperationalConsulting",
    component: OperationalConsulting,
    meta: { title: "ייעוץ תפעולי - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/transportationConsulting",
    name: "TransportationConsulting",
    component: TransportationConsulting,
    meta: { title: "ייעוץ לפרויקט תחבורה - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/wasteConsulting",
    name: "WasteConsulting",
    component: WasteConsulting,
    meta: { title: "ייעוץ אשפה ופסולת - RVc רוני ולטמן יועץ תפעול ותחזוקה" },
  },
  {
    path: "/sludgeFund",
    name: "SludgeFund",
    component: SludgeFund,
    meta: { title: "קרן פחת | קרן תחזוקה לטווח ארוך - RVc" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "RVc - רוני ולטמן יועץ תפעול ותחזוקה";
  next();
});

export default router;
