import { createStore } from "vuex";

export default createStore({
  state: {
    direction: "ltr",
  },
  getters: {
    getDirection: (state) => state.direction,
  },
  mutations: {
    setDirection(state, direction) {
      state.direction = direction;
    },
  },
  actions: {
    updateDirection({ commit }, direction) {
      commit("setDirection", direction);
    },
  },
  modules: {},
});
