<template>
  <v-locale-provider :rtl="true">
    <v-card v-if="isValid" class="ma-12">
      <div class="carousel">
        <button class="prev-slide px-1" @click="next">&lt;</button>
        <div
          class="carousel__sliders__container"
          ref="carousel__sliders__container"
        >
          <div
            class="carousel__sliders"
            :class="{ 'slide-transition': sliding }"
            :style="{ transform: `translateX(${amountToMove}px)` }"
            ref="carousel__sliders"
          >
            <div
              class="slider"
              ref="slider"
              v-for="(image, index) in displayedImages"
              :key="index"
            >
              <img
                :src="getUrl(image)"
                alt="carousel image"
                class="company_logo"
              />
            </div>
          </div>
        </div>
        <button class="next-slide px-1" @click="previous">&gt;</button>
      </div>
    </v-card>
  </v-locale-provider>
</template>

<script>
const AmountOnSide = 2;
export default {
  name: "ImageCarousel",
  props: {
    images: {
      type: Array,
      required: false,
      default: () => [
        { src: "assets/images/clients/AfricaIsrael.png", alt: "" },
        { src: "assets/images/clients/AirportAuthority.png", alt: "" },
        { src: "assets/images/clients/Ashstrom.png", alt: "" },
        { src: "assets/images/clients/BeerShevaCityHall.png", alt: "" },
        { src: "assets/images/clients/electricCompany.svg", alt: "" },
        { src: "assets/images/clients/HerzliyaCityHall.png", alt: "" },
        { src: "assets/images/clients/IDF.svg", alt: "" },
        { src: "assets/images/clients/JerusalemCityHall.png", alt: "" },
        { src: "assets/images/clients/MinistryOfDefense.png", alt: "" },
        { src: "assets/images/clients/TelAvivCityHall.png", alt: "" },
        { src: "assets/images/clients/TelAvivMuseum.png", alt: "" },
      ],
    },
    amountOfSlides: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      currentIndex: 0,
      sliding: false,
      step: 0,
      amountToMove: 0,
      amountOfClickes: 0,
      clicksNext: 0,
      clicksPrevious: 0,
      timeoutNext: null,
      timeoutPrevious: null,
    };
  },
  computed: {
    isValid() {
      if (this.amountOfSlides > this.images.length) {
        console.error(
          `Amount of slides (${this.amountOfSlides}) cannot be greater than the amount of images (${this.images.length})`
        );
        return false;
      }
      return true;
    },
    displayedImages() {
      let displayedImages = [];
      for (
        let i = this.currentIndex - (this.amountOfClickes + 1);
        i < this.currentIndex + this.amountOfSlides + this.amountOfClickes + 1;
        i++
      ) {
        displayedImages.push(
          this.images[this.getIndexFromPosition(i, this.images.length)]
        );
      }
      return displayedImages;
    },
  },
  watch: {
    amountOfClickes() {
      this.setSlidersWidth();
    },
  },
  methods: {
    getUrl(image) {
      return require("@/" + image.src + "");
    },
    setSlidersWidth() {
      const sliderWidth = this.$refs.slider
        ? this.$refs.slider[0].offsetWidth
        : 0;

      const carouselSlidersWidth =
        (this.step - sliderWidth) *
          (this.amountOfSlides + AmountOnSide + this.amountOfClickes * 2 + 1) +
        sliderWidth *
          (this.amountOfSlides + AmountOnSide + this.amountOfClickes * 2);

      document.documentElement.style.setProperty(
        "--sliders-width",
        `${carouselSlidersWidth}px`
      );
    },
    setStep() {
      const computedStyle = window.getComputedStyle(
        this.$refs.carousel__sliders__container
      );

      const carouselSlidersContainerWidth =
        this.$refs.carousel__sliders__container.offsetWidth -
        parseFloat(computedStyle.paddingLeft) -
        parseFloat(computedStyle.paddingRight);

      const sliderWidth = this.$refs.slider
        ? this.$refs.slider[0].offsetWidth
        : 0;

      this.step =
        (carouselSlidersContainerWidth - this.amountOfSlides * sliderWidth) /
          (this.amountOfSlides + 1) +
        sliderWidth;
      this.setSlidersWidth();
    },
    previous() {
      this.clicksPrevious++;
      this.amountOfClickes++;
      this.amountToMove = this.step * (this.clicksPrevious - this.clicksNext);
      this.sliding = true;
      clearTimeout(this.timeoutPrevious);
      if (this.clicksNext > 0) {
        clearTimeout(this.timeoutNext);
      }
      this.timeoutPrevious = setTimeout(() => {
        this.sliding = false;
        this.currentIndex = this.getIndexFromPosition(
          this.currentIndex + this.clicksPrevious - this.clicksNext,
          this.images.length
        );
        this.amountOfClickes = 0;
        this.clicksPrevious = 0;
        this.clicksNext = 0;
        this.amountToMove = 0;
      }, 2200);
    },
    next() {
      console.log("next");
      this.clicksNext++;
      this.amountOfClickes++;
      this.amountToMove = this.step * (this.clicksPrevious - this.clicksNext);
      this.sliding = true;
      clearTimeout(this.timeoutNext);
      if (this.clicksPrevious > 0) {
        clearTimeout(this.timeoutPrevious);
      }
      this.timeoutNext = setTimeout(() => {
        this.sliding = false;
        this.currentIndex = this.getIndexFromPosition(
          this.currentIndex - this.clicksNext + this.clicksPrevious,
          this.images.length
        );
        this.amountOfClickes = 0;
        this.clicksNext = 0;
        this.clicksPrevious = 0;
        this.amountToMove = 0;
      }, 2200);
    },
    getIndexFromPosition(position, arrayLength) {
      if (position < 0) {
        while (position < 0) {
          position += arrayLength;
        }
        return position;
      }
      if (position > arrayLength - 1) {
        while (position > arrayLength - 1) {
          position -= arrayLength;
        }
        return position;
      }
      return position;
    },
  },
  mounted() {
    if (this.isValid) {
      this.setStep();
      window.addEventListener("resize", this.setStep);
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setStep);
  },
};
</script>

<style>
:root {
  --logo-width: 9.5rem;
  --logo-height: 9.5rem;
}
.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background: rgba(239, 241, 244, 0.296);
}

.carousel__sliders__container {
  width: 100%;
  overflow: hidden;
  /* background: rgba(180, 221, 214, 0.439); */
  background: #62f37d7a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;
}

.carousel__sliders {
  min-width: var(--sliders-width);
  max-width: var(--sliders-width);
  display: flex;
  align-items: center;
  justify-content: space-evenly !important;
}

.prev-slide {
  margin: 1rem;
  font-size: 3rem;
}

.next-slide {
  margin: 1rem;
  font-size: 3rem;
}

.company_logo {
  width: var(--logo-width) !important;
  height: var(--logo-height) !important;
  object-fit: contain;
}

.slide-transition {
  transition: transform 2s ease-in-out;
}
</style>
