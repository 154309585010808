<template>
  <div class="container">
    <footer>
      <div class="footer-details">
        <div class="footer-section">
          <h3>{{ $t("Services") }}</h3>
          <ul class="px-3">
            <div v-for="(service, key) in serviesData" :key="key">
              <li class="my-2 border_down" @click="navigateTo(service.route)">
                {{ service[$i18n.locale] }}
              </li>
              <!-- <v-divider
                :thickness="2"
                class="border-opacity-50"
                color="error"
              ></v-divider> -->
            </div>
          </ul>
        </div>
        <div class="footer-section">
          <h3>{{ $t("About.title") }}</h3>
          <p style="max-width: 500px">{{ aboutUs[$i18n.locale] }}</p>
        </div>
        <div class="footer-section">
          <h3>{{ $t("ContactInformation") }}</h3>
          <p>{{ $t("Phone") }}: {{ phoneNumber }}</p>
          <p>{{ $t("Email") }}: {{ email }}</p>
        </div>
        <div class="footer-section">
          <v-btn class="round-button" @click="navigateTo('/contact')">{{
            $t("NavBarHeader.Contact")
          }}</v-btn>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ContactInfoCard",
  data() {
    return {
      email: "rvee1947@gmail.com",
      aboutUs: {
        he: "משרדנו עוסק בייעוץ בכל התחומים הקשורים לפרוגרמה, תכנון מהיבט ניהול, תפעול, אחזקה, ניקיון, סקרי מצב נכסים ואומדן עלויות שיקום ושירותים נוספים לתשתיות, מבנים ומערכות במוסדות וארגונים שונים.",
        en: "Our office specializes in consulting in all areas related to the program, planning from a management perspective, operations, maintenance, cleaning, property condition surveys, and estimating rehabilitation costs and additional services for infrastructure, buildings, and systems in various institutions and organizations.",
      },
      serviesData: {
        OperationalDesign: {
          route: "/operationalDesign",
          he: "בינוי תומך תפעול",
          en: "Operational Design",
        },
        MaintenanceConsulting: {
          route: "/maintenanceConsulting",
          he: "ייעוץ אחזקה",
          en: "Maintenance Consulting",
        },
        OperationalConsulting: {
          route: "/operationalConsulting",
          he: "ייעוץ תפעולי",
          en: "Operational Consulting",
        },
        TransportationConsulting: {
          route: "/transportationConsulting",
          he: "ייעוץ לפרוייקטי תחבורה",
          en: "Transportation Consulting",
        },
        WasteConsulting: {
          route: "/WasteConsulting",
          he: "יעוץ אשפה",
          en: "Waste Consulting",
        },
        SludgeFund: { route: "/sludgeFund", he: "קרן פחת", en: "Sludge Fund" },
      },
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
      window.scrollTo(0, 0);
    },
  },
  computed: {
    phoneNumber() {
      return this.$i18n.locale === "en" ? "+972-54-4780650" : "972-54-4780650+";
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 7rem;
}

footer {
  background-color: #b6dbd9;
  padding: 6rem 2rem;
}

.footer-details {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.footer-section {
  margin: 2rem;
  padding: 2rem;
}

.round-button {
  font-size: 2rem;
  width: 18rem;
  height: 6rem;
  border-radius: 50px;
  background: rgba(253, 205, 187, 0.802);
}

ul {
  list-style-type: none;
}

li {
  cursor: pointer;
  font-size: 1.65rem;
}

h3 {
  font-size: 1.9rem;
  margin-bottom: 1.5rem;
}

p {
  font-size: 1.65rem;
  margin: 0.3rem;
}

.border_down {
  border-bottom: 2px dotted rgba(239, 75, 75, 0.644);
  max-width: 17rem;
}

@media screen and (min-width: 768px) {
  .footer-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .footer-section {
    margin: none;
    padding: none;
  }
  h3 {
    font-size: 2.3rem;
  }
  li {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1200px) {
  .footer-details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  h3 {
    font-size: 2.5rem;
  }
  li {
    font-size: 1.9rem;
  }
  p {
    font-size: 1.9rem;
  }
}
</style>
