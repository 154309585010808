<template>
  <v-locale-provider :rtl="directionClass === 'rtl'">
    <div style="background-color: rgb(230 221 190 / 98%) !important">
      <v-app style="background: rgb(230 221 190 / 98%) !important">
        <div>
          <NavBar />
          <router-view class="router" />
        </div>
      </v-app>
      <ImageCarousel :amountOfSlides="amountOfSlides"></ImageCarousel>
      <ContactInfoCard></ContactInfoCard>
    </div>
  </v-locale-provider>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import ContactInfoCard from "./components/ContactFooterCard.vue";
import ImageCarousel from "./components/ImageCarousel.vue";

export default {
  name: "App",
  components: {
    NavBar,
    ContactInfoCard,
    ImageCarousel,
  },
  // created() {
  //   this.$router.push("/");
  // },
  data() {
    return {
      amountOfSlides: 5,
    };
  },
  methods: {
    setAmountOfSlides() {
      const width = window.innerWidth;
      if (width < 500) {
        this.amountOfSlides = 1;
      } else if (width < 800) {
        this.amountOfSlides = 2;
      } else if (width < 1100) {
        this.amountOfSlides = 3;
      } else if (width < 1550) {
        this.amountOfSlides = 4;
      } else if (width < 1800) {
        this.amountOfSlides = 5;
      } else {
        this.amountOfSlides = 6;
      }
    },
  },
  created() {
    this.setAmountOfSlides();
    window.addEventListener("resize", this.setAmountOfSlides);
  },
  computed: {
    directionClass() {
      return this.$i18n.locale === "he" ? "rtl" : "ltr";
    },
  },
  watch: {
    "$i18n.locale"(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        if (newLocale !== oldLocale) {
          localStorage.setItem("lang", newLocale);
        }
        this.$store.dispatch(
          "updateDirection",
          newLocale === "he" ? "rtl" : "ltr"
        );
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "SF Hebrew";
  src: url("../fonts/SFHebrewFonts/SF-Hebrew-Rounded.ttf") format("truetype");
}
@font-face {
  font-family: "Cursiv";
  src: url("../fonts/Cursiv/CURSIV.TTF") format("truetype");
  font-display: swap;
}
html {
  font-size: 62.5% !important;
}
body {
  font-size: 16px;
  /* font-family: Inter, Arial, Helvetica, sans-serif; */
}

.router {
  margin-top: 6.6rem;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.v-app {
  background: #571616;
}
/* Headings */

h2 {
  font-size: 3.5rem;
}
h3 {
  font-size: 2.7rem;
}

.components__container {
  width: 92.5%;
  margin-right: auto;
  margin-left: auto;
}

.components__container_split-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.margin-11 {
  margin-top: 11rem;
}

/* #f2ece1 */

/* content Pages */

.content-page_first {
  width: 100%;
}
.content-page_second {
  width: 100%;
}

.content-page_section {
  margin-bottom: 2rem;
}

.content-page_section_header {
  margin-bottom: 1rem;
}
.content-page_section_content {
  font-size: 1.75rem;
}
.content-page_section_content li {
  line-height: 3.6rem;
  margin-left: 4rem;
  margin-right: 4rem;
}

.content-page_main_header {
  width: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-bottom: 4rem;
}

.contant-page_contact-form {
  width: 100%;
  max-width: 600px;
  padding: 3rem;
  padding-top: 6rem;
  margin: 0 auto;
}
.contant-page_contact-form_header {
  font-size: 2.5rem;
  margin-bottom: 5rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 950px) {
  .content-page_first {
    width: 60%;
  }
  .content-page_second {
    width: 40%;
  }
  .content-page_section_content {
    font-size: 1.95rem;
  }
  .components__container_split-page {
    flex-direction: row;
  }
  .components__container {
    width: 90%;
  }
  .content-page_main_header {
    margin-left: 5rem;
    margin-right: 5rem;
    padding-bottom: 6rem;
  }
  .content-page {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media screen and (min-width: 1150px) {
  .components__container {
    width: 85%;
  }
}

@media screen and (min-width: 1600px) {
  .components__container {
    width: 85%;
    /* max-width: 1700px; */
  }
}
</style>
