<template>
  <div class="flip-card">
    <div class="display-card">
      <div class="display-card__front">
        <img
          v-if="this.frontImage"
          :src="getFrontUrl()"
          class="project__image"
          alt="card's front background image"
        />
        <div>
          <slot name="front-icon">
            <v-icon v-if="this.frontContent.icon" class="ma-5">
              <img :src="getIcon(frontContent.icon)" alt="card front icon" />
            </v-icon>
          </slot>
          <div v-if="this.frontContent.title" class="display-card_title">
            <slot name="front-title">
              {{ frontContent.title }}
            </slot>
          </div>
          <v-card-text v-if="this.frontContent.text" class="display-card_text">
            <slot name="front-text">
              {{ frontContent.text }}
            </slot>
          </v-card-text>
          <slot name="front-custom-content"></slot>
          <slot name="front-button" v-if="frontContent.buttonText">
            <v-btn :to="frontContent.linkToPage">{{
              frontContent.buttonText
            }}</v-btn>
          </slot>
        </div>
      </div>
      <div class="display-card__back">
        <!-- Use slots to allow custom content for the back side -->
        <img
          v-if="this.backImage"
          :src="getBacktUrl()"
          class="project__image"
          alt="card's back background image"
        />
        <div>
          <slot name="back-icon">
            <v-icon v-if="this.backContent.icon" class="ma-5">
              <img :src="getIcon(backContent.icon)" alt="card back icon" />
            </v-icon>
          </slot>
          <div v-if="this.backContent.title" class="display-card_title">
            <slot name="back-title">
              <h2>{{ backContent.title }}</h2>
            </slot>
          </div>
          <v-card-text v-if="this.backContent.text" class="display-card_text">
            <slot name="back-text">
              {{ backContent.text }}
            </slot>
          </v-card-text>
          <slot name="back-custom-content"></slot>
          <slot name="back-button" v-if="backContent.buttonText">
            <v-btn :to="backContent.linkToPage">{{
              backContent.buttonText
            }}</v-btn>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayCard",
  props: {
    frontImage: {
      type: Object,
      required: false,
    },
    backImage: {
      type: Object,
      required: false,
    },
    frontContent: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: "",
          text: "",
        };
      },
    },
    backContent: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: "",
          text: "",
        };
      },
    },
  },
  data() {
    return {
      isFlipped: false,
    };
  },
  methods: {
    getFrontUrl() {
      if (!this.frontImage) {
        return "";
      }
      return require("@/" + this.frontImage.url + "");
    },
    getBacktUrl() {
      if (!this.backImage) {
        return "";
      }
      return require("@/" + this.backImage.url + "");
    },
    getIcon(url) {
      if (!url) {
        return "";
      }
      return require("@/" + url + "");
    },
  },
};
</script>

<style scoped>
* {
  font-size: 16px;
}

.flip-card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.display-card {
  width: 100%;
  height: 100%;
  min-width: 200px;
  min-height: 200px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.flip-card:hover .display-card {
  transform: rotateY(180deg);
}

.display-card__front,
.display-card__back {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* overflow: hidden; */
}

.display-card__back {
  transform: rotateY(180deg);
}

.project__image {
  border-radius: 4px;
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.display-card_title {
  font-size: 2.3rem;
  font-weight: 650;
  margin: auto;
  margin: 1.5rem;
}
.display-card_text {
  font-size: 1.75rem;
  font-weight: 500;
  margin: auto;
  margin: 1.5rem;
}

@media screen and (min-width: 900px) {
  .display-card_title {
    font-size: 2.1rem;
    font-weight: 650;
  }
}
@media screen and (min-width: 1600px) {
  .display-card_title {
    font-size: 2.3rem;
    font-weight: 700;
  }
  .display-card_text {
    font-size: 1.9rem;
  }
}
</style>
