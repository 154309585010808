<template>
  <div class="form__container">
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row v-if="!shortForm">
        <v-col cols="12" md="6" class="py-0">
          <v-text-field
            v-model="firstName"
            :label="$t('FirstName')"
            :rules="nameRules"
            :counter="nameMaxLength()"
            variant="outlined"
            :maxlength="nameMaxLength()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="py-0">
          <v-text-field
            v-model="lastName"
            :label="$t('LastName')"
            :rules="nameRules"
            :counter="nameMaxLength()"
            :maxlength="nameMaxLength()"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="firstName"
            :label="$t('FullName')"
            :rules="nameRules"
            :counter="nameMaxLength()"
            :maxlength="nameMaxLength() + 30"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!shortForm">
        <v-col cols="12" md="5" class="py-0">
          <v-text-field
            v-model="email"
            :label="$t('Email')"
            :rules="emailRules"
            :maxlength="emailMaxLength()"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="phone"
            :label="$t('Phone')"
            :rules="phoneRules"
            :maxlength="phoneMaxLength()"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="py-0">
          <v-autocomplete
            v-model="countryCode"
            :items="countryCodeItems"
            variant="outlined"
            @update:modelValue="handleChange"
            update:modelValue="handleChange"
            :menu-props="{ contentClass: 'custom-menu-content' }"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="email"
            :label="$t('Email')"
            :rules="emailRules"
            :maxlength="emailMaxLength()"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="phone"
            :label="$t('Phone')"
            :rules="shortPhoneRules"
            :maxlength="phoneMaxLength() + 20"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="subject"
            :label="$t('Subject')"
            :rules="subjectRules"
            :counter="subjectMaxLength()"
            :maxlength="subjectMaxLength()"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!shortForm">
        <v-col cols="12" class="py-0">
          <v-textarea
            v-model="message"
            :label="$t('Message')"
            :rules="messageRules"
            :counter="messageMaxLength()"
            :maxlength="messageMaxLength()"
            variant="outlined"
            auto-grow
            class="message__textarea"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn class="btn__submit" @click="submitForm">
            {{ $t("Submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import countyCodes from "../assets/countryCode.json";
import "resize-observer-polyfill";

const nameMaxLength = 30;
const emailMaxLength = 255;
const phoneMaxLength = 15;
const subjectMaxLength = 100;
const messageMaxLength = 2000;

export default {
  name: "ContactUsForm",
  // Your component options here
  props: {
    shortForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "+972",
      subject: "",
      message: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (v && v.length <= nameMaxLength) ||
          "Name must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
          "Please enter a valid email address",
        (v) =>
          (v && v.length <= emailMaxLength) ||
          "Email must be 255 characters or less",
      ],
      shortPhoneRules: [
        (v) => !!v || "Phone number is required",
        (v) =>
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(v) ||
          "Please enter a valid phone number with a country code",
      ],
      subjectRules: [
        (v) => !!v || "Subject is required",
        (v) =>
          (v && v.length <= subjectMaxLength) ||
          "Subject must be less than 100 characters",
      ],
      messageRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length <= messageMaxLength) ||
          "Message must be less than 500 characters",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) => /^\d{1,15}$/.test(v) || "Please enter a valid phone number",
      ],
    };
  },
  methods: {
    sendEmail() {
      let fullPhone;
      if (this.shortForm === true) {
        fullPhone = this.phone;
      } else {
        fullPhone = this.countryCode + " " + this.phone;
      }
      const templateParams = {
        from_name: this.firstName + " " + this.lastName,
        from_email: this.email,
        message: this.message,
        subject: this.subject,
        phone: fullPhone,
      };
      emailjs
        .send(
          "service_3z2c1gg",
          "template_eyzhi2p",
          templateParams,
          "VQqTjRywVXcUyzgEk"
        )
        .then(
          () => {
            this.clearForm();
            console.log("Email Sent Successfully");
          },
          (error) => {
            console.log(error.text);
          }
        );
    },
    async submitForm() {
      debugger;
      const isValid = await this.$refs.form.validate();
      if (!isValid.valid) return;
      this.sendEmail();
    },
    clearForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    initializeCountryCode() {
      this.countryCode = {
        title: this.$i18n.locale === "en" ? "Israel (+972)" : "ישראל (972+)",
        value: "+972",
      };
    },
    handleChange() {
      if (this.countryCode === null) {
        this.countryCode = "+972";
      }
    },
    nameMaxLength() {
      return nameMaxLength;
    },
    emailMaxLength() {
      return emailMaxLength;
    },
    subjectMaxLength() {
      return subjectMaxLength;
    },
    messageMaxLength() {
      return messageMaxLength;
    },
    phoneMaxLength() {
      return phoneMaxLength;
    },
    // TODO: Add a rechptcha check with google when a domain is purchased (needs a backend server)
  },
  computed: {
    countryCodeItems() {
      let countryCodeItems = [];
      let max_length = this.$i18n.locale === "en" ? 20 : 23;
      for (let i = 0; i < countyCodes.length; i++) {
        if (
          countyCodes[i].name[this.$i18n.locale] === undefined ||
          countyCodes[i].dial_code === undefined
        ) {
          continue;
        }
        if (
          countyCodes[i].name[this.$i18n.locale].length +
            countyCodes[i].dial_code.length +
            3 >
          max_length
        ) {
          continue;
        }
        if (this.$i18n.locale === "en") {
          countryCodeItems.push({
            title:
              countyCodes[i].name[this.$i18n.locale] +
              " (" +
              countyCodes[i].dial_code +
              ")",
            value: countyCodes[i].dial_code,
          });
        } else {
          countryCodeItems.push({
            title:
              countyCodes[i].name[this.$i18n.locale] +
              " (" +
              countyCodes[i].dial_code.slice(1) +
              "+" +
              ")",
            value: countyCodes[i].dial_code,
          });
        }
      }
      return countryCodeItems;
    },
  },
  created() {},
  watch: {
    "$i18n.locale"(newLocale, oldLocale) {
      if (
        newLocale !== oldLocale &&
        (this.countryCode.value === "" || this.countryCode.value === "+972")
      ) {
        // this.initializeCountryCode();
      }
    },
  },
};
</script>

<style scoped>
html {
  font-size: 16px !important;
}
.form__container {
  /* width: 80%; */
  /* max-width: 1000px; */
  margin: 0 auto;
}

.form__container ::v-deep .v-label {
  font-size: 2rem;
}

.btn__submit {
  font-size: 2rem;
  background-color: #4ce1c38f;
}

.v-field,
.v-list-item-title {
  font-size: 0.875rem !important;
}

:global(.custom-menu-content .v-list-item__content > .v-list-item-title) {
  font-size: 1.7rem !important;
}
</style>
