import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createI18n } from "vue-i18n";
import { createHead } from "@vueuse/head";

loadFonts();

import en from "../locales/en.json";
import he from "../locales/he.json";

const i18n = createI18n({
  locale: localStorage.getItem("lang") || "he",
  fallbackLocale: "he",
  messages: {
    en,
    he,
  },
});

const head = createHead();

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(head);

app.mount("#app");
